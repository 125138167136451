import { useMemo } from 'react';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';

const SHOW_SENSITIVE_LOCAL_STORAGE_KEY = 'show-sensitive';

export const getShowSensitiveOnLocal = () => {
  try {
    const value = localStorage.getItem(SHOW_SENSITIVE_LOCAL_STORAGE_KEY);
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return;
    }
  } catch {
    return;
  }
};

export const setShowSensitiveOnLocal = (value: boolean) => {
  try {
    localStorage.setItem(
      SHOW_SENSITIVE_LOCAL_STORAGE_KEY,
      value ? 'true' : 'false'
    );
  } catch {}
};

export const useShowSensitive = () => {
  const { session } = useTellerSession();
  const showSensitive = useMemo(() => {
    if (session?.tellerUser?.showSensitive) {
      // 更新
      setShowSensitiveOnLocal(session?.tellerUser?.showSensitive);
      return session?.tellerUser?.showSensitive;
    } else {
      return getShowSensitiveOnLocal();
    }
  }, [session?.tellerUser?.showSensitive]);

  return {
    showSensitive,
  };
};
