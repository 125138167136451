import { useRouter } from 'next/navigation';
import { useCallback, useMemo, useState } from 'react';
import { useRef } from 'react';
import { useFirebaseAnalytics } from '~/common/hooks/use-firebase-analytics';
import { routes } from '~/utils/routes';
import type {
  SeriesSuggestItem,
  TagSuggestItem,
  UserSuggestItem,
} from './types';
import { useSearchHistory } from './use-search-history';
import { useSuggestion } from './use-suggestion';

// キーワードがタグと一致しているときに、このしきい値以上の件数があればタグ検索に遷移する
const LINK_TO_TAG_THRESHOLD = 100;

type Params = {
  onClickItem?: () => void;
};

export const useSearchContainer = ({ onClickItem }: Params) => {
  const router = useRouter();
  const { logEvent } = useFirebaseAnalytics();
  const [inputText, setInputText] = useState('');
  const handleOnChangeText = useCallback((text: string) => {
    setInputText(text);
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  const { suggestions, isLoading, isEmptyResult } = useSuggestion(inputText);

  const { latestSearches, removeHistory, addHistory } = useSearchHistory();
  const histories = useMemo(() => {
    if (inputText.length > 0) return;

    return latestSearches;
  }, [inputText.length, latestSearches]);

  const handleOnClickTag = useCallback(
    ({
      tagName,
      position,
    }: {
      position: number;
    } & TagSuggestItem) => {
      logEvent('search_suggestion_tag_clicked', {
        tag: tagName,
        keyword: inputText,
        position,
      });
      addHistory(tagName);
      router.push(routes.tag.tagDetail.url(tagName));
      onClickItem?.();
    },
    [addHistory, inputText, logEvent, onClickItem, router]
  );

  const handleOnClickSeries = useCallback(
    ({
      seriesId,
      title,
      isOfficial,
      position,
    }: {
      position: number;
    } & SeriesSuggestItem) => {
      logEvent('search_suggestion_series_clicked', {
        series_id: seriesId,
        title: title,
        isOfficial: isOfficial,
        keyword: inputText,
        position,
      });
      addHistory(title);
      router.push(routes.series.url(seriesId));
      onClickItem?.();
    },
    [inputText, logEvent, onClickItem, router, addHistory]
  );

  const handleOnClickUser = useCallback(
    ({
      userId,
      name,
      position,
    }: {
      position: number;
    } & UserSuggestItem) => {
      logEvent('search_suggestion_user_clicked', {
        user_id: userId,
        user_name: name,
        keyword: inputText,
        position,
      });
      addHistory(name);
      router.push(routes.user.userDetail.url(userId));
      onClickItem?.();
    },
    [inputText, logEvent, onClickItem, router, addHistory]
  );

  // history
  const handleOnClickHistory = useCallback(
    (keyword: string) => {
      router.push(routes.search.url(keyword));
      onClickItem?.();
    },
    [onClickItem, router]
  );
  const handleOnClickDeleteHistoryItem = useCallback(
    (keyword: string) => {
      removeHistory(keyword);
    },
    [removeHistory]
  );

  // handle submit
  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();

      if (inputText) {
        logEvent('search_submit', {
          keyword: inputText,
        });
        addHistory(inputText);
        onClickItem?.();

        // タグ検索に遷移するかどうかを判定する
        if (
          suggestions?.tags?.some(
            (tag) =>
              tag.count > LINK_TO_TAG_THRESHOLD && tag.tagName === inputText
          )
        ) {
          router.push(routes.tag.tagDetail.url(inputText));
        } else {
          const isTagContext = inputText.startsWith('#');

          router.push(
            routes.search.url(inputText, isTagContext ? 'tags' : undefined)
          );
        }
      }
    },
    [addHistory, inputText, logEvent, onClickItem, router, suggestions?.tags]
  );

  const blurInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }, []);

  return {
    handleOnChangeText,
    histories,
    suggestions,
    isLoading,
    isEmptyResult,
    handleOnClickTag,
    handleOnClickSeries,
    handleOnClickUser,
    handleOnClickHistory,
    handleOnClickDeleteHistoryItem,
    handleSubmit,
    inputRef,
    blurInput,
  };
};
