import { HStack, Paragraph } from '@styled-system/jsx';

type Config = Partial<{
  separator: boolean;
}>;

const DEFAULT_CONFIG: Config = {
  separator: true,
};

type Props = {
  title?: string;
  label?: string;
  onClickItem?: () => void;
  config?: Config;
};

export const SearchSuggestItem = ({
  title,
  label,
  onClickItem,
  config,
}: Props) => {
  const enableSeparator = config?.separator ?? DEFAULT_CONFIG.separator;

  return (
    <HStack
      w="100%"
      h="40px"
      p="10px 16px 10px 16px"
      justifyContent="space-between"
      cursor="pointer"
      borderBottom={{
        base: enableSeparator ? `1px solid token(colors.separator)` : undefined,
        lg: 'none',
      }}
      lg={{
        borderRadius: '8px',
      }}
      _hover={{
        bg: 'strong_bg',
      }}
      onClick={onClickItem}
    >
      {title && (
        <Paragraph fontSize="size_12" color="accent.1000" fontWeight="bold">
          {title}
        </Paragraph>
      )}
      {label && (
        <Paragraph
          fontSize="size_10"
          color="accent.500"
          minW="65px"
          textAlign="right"
        >
          {label}
        </Paragraph>
      )}
    </HStack>
  );
};
