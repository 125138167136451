import { SearchContainer } from './search-container';
import { ModalProps, SearchModal } from './search-modal';

type Props = ModalProps;

export const SearchModalContainer = ({ isOpen, onClose }: Props) => {
  return (
    <SearchModal isOpen={isOpen} onClose={onClose}>
      <SearchContainer onClickItem={onClose} onClickClose={onClose} />
    </SearchModal>
  );
};
