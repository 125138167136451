import { useCallback } from 'react';
import useSWR, { mutate } from 'swr';
import {
  getObjectFromLocalStorage,
  setObjectToLocalStorage,
} from '~/repositories/local-storage-repository';
const LATEST_SEARCHES_KEY = '/api/latestSearches';
const HISTORY_LIMIT = 25;

const removeSearch = (keyword: string): void => {
  const searches = fetchLatestSearches();
  const existsIdx = searches.indexOf(keyword);
  if (existsIdx > -1) {
    searches.splice(existsIdx, 1);
  }
  setObjectToLocalStorage(LATEST_SEARCHES_KEY, searches);
};

const saveSearch = (keyword: string): void => {
  const searches = fetchLatestSearches();
  if (searches) {
    const existsIdx = searches.indexOf(keyword);
    if (existsIdx > -1) {
      searches.splice(existsIdx, 1);
    }
    searches.unshift(keyword);
    setObjectToLocalStorage(
      LATEST_SEARCHES_KEY,
      searches.slice(0, HISTORY_LIMIT)
    );
  }
};

// Latest searches
const fetchLatestSearches = (): string[] =>
  getObjectFromLocalStorage<string[]>(LATEST_SEARCHES_KEY) || [];

export const useSearchHistory = () => {
  const { data: latestSearches } = useSWR(
    LATEST_SEARCHES_KEY,
    fetchLatestSearches
  );

  // Latest searches operations
  const removeHistory = useCallback((keyword: string): void => {
    removeSearch(keyword);
    mutate(LATEST_SEARCHES_KEY);
  }, []);

  const addHistory = useCallback((keyword) => {
    saveSearch(keyword);
    mutate(LATEST_SEARCHES_KEY);
  }, []);

  return {
    latestSearches,
    removeHistory,
    addHistory,
  };
};
