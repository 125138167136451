import { Paragraph } from '@styled-system/jsx';

type Props = {
  title: string;
};

export const SearchSectionTitle = ({ title }: Props) => (
  <Paragraph
    fontSize={{ base: 'size_12', lg: 'size_14' }}
    color="content_primary"
    textAlign="left"
    fontWeight="bold"
    backgroundColor="surface_secondary"
    height="36px"
    lineHeight="36px"
    border="1px solid token(accent.100)"
    w="100%"
    mt="16px"
    px="16px"
  >
    {title}
  </Paragraph>
);
