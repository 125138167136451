import { useCallback, useMemo } from 'react';
import { useFetchStringValue } from '~/common/hooks/use-remote-config-swr/use-remote-config-swr';

export const useNgKeywords = () => {
  const { data: ngKeywordsData } = useFetchStringValue('ng_keywords', true);
  // FIXME: AndroidでNGキーワードに以下のワードを入れると不具合が起こるので、一旦以下のワードはWebは固定で入れる
  // https://tellernovel.slack.com/archives/C92PQ5XB4/p1676993249398429
  const ngKeywords = useMemo(
    () => [
      'メイドプレイ(?)',
      '配信プレイ（?)',
      '目隠しプレイ(?)',
      ...(ngKeywordsData?.split(',') ?? []),
    ],
    [ngKeywordsData]
  );

  const isNgKeywords = useCallback(
    (keyword) => {
      return ngKeywords
        .map((x: string) => x.toLowerCase())
        .includes(keyword.toLowerCase());
    },
    [ngKeywords]
  );

  return {
    ngKeywords,
    isNgKeywords,
  };
};
