import { MdClose } from 'react-icons/md';
import { Loading } from '~/app/_components/loading';
import { SearchHistoryItem } from '~/app/_components/search-history-item';
import { SearchSuggestItem } from '~/app/_components/search-suggest-item';
import { SearchSuggestUserItem } from '~/app/_components/search-suggest-user-item';
import { Color } from '~/utils/styles/enums';
import { SearchBar } from '@app/_components/search-bar';
import { css } from '@styled-system/css';
import { HStack, Paragraph, VStack } from '@styled-system/jsx';
import { SearchSectionTitle } from './search-section-title';
import { useSearchContainer } from './use-search-container';
type Props = {
  onClickItem?: () => void;
  onClickClose?: () => void;
};

export const SearchContainer = ({ onClickItem, onClickClose }: Props) => {
  const {
    handleOnChangeText,
    histories,
    suggestions,
    isLoading,
    isEmptyResult,
    handleOnClickUser,
    handleOnClickSeries,
    handleOnClickTag,
    handleOnClickHistory,
    handleOnClickDeleteHistoryItem,
    handleSubmit,
    inputRef,
    blurInput,
  } = useSearchContainer({ onClickItem });

  return (
    <VStack
      w="100%"
      h="100%"
      gap="0"
      py={{
        base: '16px',
        lg: '24px',
      }}
    >
      <HStack w="100%" px="16px" flexShrink="0" gap="16px">
        <MdClose
          color={Color.ACCENT_1000}
          size={24}
          aria-label="閉じる"
          onClick={onClickClose}
          className={css({
            flexShrink: 0,
            cursor: 'pointer',
          })}
        />
        <form
          onSubmit={handleSubmit}
          className={css({
            flex: 1,
          })}
        >
          <SearchBar
            type="search"
            inputMode="search"
            autoFocus
            onChangeText={handleOnChangeText}
            inputRef={inputRef}
          />
        </form>
      </HStack>
      {isLoading && <Loading />}
      {isEmptyResult && (
        <Paragraph
          fontSize="size_14"
          color="accent.500"
          textAlign="center"
          fontWeight="bold"
          w="100%"
          mt="16px"
          p="20px"
        >
          検索結果が見つかりませんでした
        </Paragraph>
      )}

      <VStack
        w="100%"
        h="100%"
        gap="8px"
        overflowY="scroll"
        onTouchStart={blurInput}
      >
        {!suggestions && !isLoading && (
          <>
            <SearchSectionTitle title="最近の検索" />
            {/* History */}
            {histories?.map((history, index) => (
              <SearchHistoryItem
                key={`history-${history}-${index}`}
                text={history}
                onClickItem={() => handleOnClickHistory(history)}
                onClickDelete={() => handleOnClickDeleteHistoryItem(history)}
              />
            ))}
            {histories?.length === 0 && (
              <Paragraph
                fontSize="size_14"
                color="content_tertiary"
                marginRight="auto"
                padding="8px 16px"
              >
                最近の検索はありません
              </Paragraph>
            )}
          </>
        )}

        {/* Tag */}
        {(suggestions?.tags?.length || 0) > 0 && (
          <>
            <SearchSectionTitle title="タグ" />

            {suggestions?.tags?.map((tag, index) => (
              <SearchSuggestItem
                key={`tag-${tag.tagName}-${index}`}
                title={`#${tag.tagName}`}
                label={`${tag.count.toLocaleString()}件`}
                onClickItem={() =>
                  handleOnClickTag({ ...tag, position: index })
                }
              />
            ))}
          </>
        )}

        {/* Series */}
        {(suggestions?.officialSeries?.length ||
          suggestions?.indiesSeries?.length ||
          0) > 0 && <SearchSectionTitle title="作品" />}

        {/* OfficialSeries */}
        {suggestions?.officialSeries?.map((series, index) => (
          <SearchSuggestItem
            key={`offical-series-${series.seriesId}-${index}`}
            title={series.title}
            onClickItem={() =>
              handleOnClickSeries({ ...series, position: index })
            }
          />
        ))}

        {/* IndiesSeries */}
        {suggestions?.indiesSeries?.map((series, index) => (
          <SearchSuggestItem
            key={`indies-series-${series.seriesId}-${index}`}
            title={series.title}
            onClickItem={() =>
              handleOnClickSeries({ ...series, position: index })
            }
          />
        ))}
        {/* User */}
        {(suggestions?.users?.length || 0) > 0 && (
          <>
            <SearchSectionTitle title="ユーザー" />
            {suggestions?.users?.map((user, index) => (
              <SearchSuggestUserItem
                key={`user-${user.userId}-${index}`}
                title={user.name}
                thumb={user.thumbnail}
                onClickItem={() =>
                  handleOnClickUser({ ...user, position: index })
                }
              />
            ))}
          </>
        )}
      </VStack>
    </VStack>
  );
};
