import {
  fetchAndActivate,
  getRemoteConfig,
  getString,
} from 'firebase/remote-config';
import { firebaseApp } from '~/lib/firebase';

const remoteConfig = (() => {
  if (typeof window === 'undefined') return null;
  return getRemoteConfig(firebaseApp);
})();

const initialize = (): Promise<boolean> => {
  if (remoteConfig === null) return Promise.resolve(false);
  remoteConfig.settings = {
    fetchTimeoutMillis: 3_600_000,
    minimumFetchIntervalMillis: 3_600_000,
  };
  return fetchAndActivate(remoteConfig);
};

export const fetchString = (key: string): Promise<string | undefined> =>
  new Promise<string | undefined>((resolve) => {
    if (remoteConfig === null) {
      Promise.resolve(undefined);
      return;
    }

    if (remoteConfig.lastFetchStatus === 'no-fetch-yet') {
      initialize().then(() => {
        resolve(getString(remoteConfig, key));
      });
    } else {
      resolve(getString(remoteConfig, key));
    }
  });
