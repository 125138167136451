import { useCallback } from 'react';
import { MdClear } from 'react-icons/md';
import { Color } from '~/utils/styles/enums';
import { Center, HStack, Paragraph } from '@styled-system/jsx';

type Config = Partial<{
  separator: boolean;
}>;

const DEFAULT_CONFIG: Config = {
  separator: true,
};

type Props = {
  text: string;
  onClickItem?: () => void;
  onClickDelete?: () => void;
  config?: Config;
};

export const SearchHistoryItem = ({
  text,
  onClickItem,
  onClickDelete,
  config,
}: Props) => {
  const enableSeparator = config?.separator ?? DEFAULT_CONFIG.separator;

  const handleOnClickDelete = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickDelete?.();
    },
    [onClickDelete]
  );

  return (
    <HStack
      w="100%"
      h="40px"
      p="10px"
      justifyContent="space-between"
      cursor="pointer"
      borderBottom={{
        base: enableSeparator ? `1px solid token(colors.separator)` : undefined,
        lg: 'none',
      }}
      lg={{
        borderRadius: '8px',
      }}
      _hover={{
        bg: 'strong_bg',
      }}
      onClick={onClickItem}
    >
      <Paragraph fontSize="size_12" color="accent.1000" fontWeight="bold">
        {text}
      </Paragraph>
      <Center p="8px" cursor="pointer" onClick={handleOnClickDelete}>
        <MdClear color={Color.ACCENT_1000} size={16} />
      </Center>
    </HStack>
  );
};
