import useSWR from 'swr';
import { fetchString } from '~/repositories/firebase-remote-config-repository';

const KEY_PREFIX = 'use-fetch-string-value-v3';
const CACHE_TIME = 3600 * 1000;

const fetcher = (remoteConfigKey: string, cacheToLocalStorage?: boolean) => {
  const cachedData =
    cacheToLocalStorage && typeof localStorage !== 'undefined'
      ? localStorage.getItem(`${KEY_PREFIX}_${remoteConfigKey}`)
      : null;
  if (cachedData !== null) {
    const json = JSON.parse(cachedData);
    if (json && Number(json.updatedAt) + CACHE_TIME > Date.now()) {
      // キャッシュ時間ないであればキャッシュを使う
      return Promise.resolve(json.data);
    } else {
      // キャッシュ時間が過ぎていたら削除
      localStorage.removeItem(`${KEY_PREFIX}_${remoteConfigKey}`);
    }
  }

  return fetchString(remoteConfigKey);
};

export const useFetchStringValue = (
  remoteConfigKey: string,
  cacheToLocalStorage?: boolean
) => {
  return useSWR(
    ['/remoteconfig', remoteConfigKey],
    ([_, k]) => fetcher(k, cacheToLocalStorage),
    {
      onSuccess(data) {
        if (
          cacheToLocalStorage &&
          typeof localStorage !== 'undefined' &&
          data
        ) {
          localStorage.setItem(
            `${KEY_PREFIX}_${remoteConfigKey}`,
            JSON.stringify({
              updatedAt: Date.now(),
              data: data,
            })
          );
        }
      },
    }
  );
};
