import { useMemo } from 'react';
import useSWR from 'swr';
import type { QueryRequestItem } from '~/app/_repositories/query-types';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { useDebounce } from '~/common/hooks/use-debounce';
import { useNgKeywords } from '~/common/hooks/use-ng-keywords';
import { useShowSensitive } from '~/common/hooks/use-show-sensitive/use-show-sensitive';
import { query_search_v2 } from '~/infra/api/rpc/_generated';
import { getQueriesForClient } from '@app/_repositories/query-service';
import type {
  SeriesSuggestItem,
  TagSuggestItem,
  UserSuggestItem,
} from './types';

type Suggestions = {
  tags: TagSuggestItem[];
  officialSeries: SeriesSuggestItem[];
  indiesSeries: SeriesSuggestItem[];
  users: UserSuggestItem[];
};

const MAX_SUGGESTION_ITEMS = 8;
const TAG_REQUEST_ID = 'searchTagSuggestions';
const SERIES_OFFICIAL_REQUEST_ID = 'searchOfficialSeriesSuggestions';
const SERIES_INDIES_REQUEST_ID = 'searchIndiesSeriesSuggestions';
const USER_REQUEST_ID = 'searchUserSuggestions';

const fetchSuggestions = async (
  keyword: string,
  showSensitive: boolean
): Promise<Suggestions> => {
  const sensitiveFilter = showSensitive
    ? query_search_v2.SensitiveFilter.SENSITIVE_FILTER_UNSPECIFIED
    : query_search_v2.SensitiveFilter.SENSITIVE_FILTER_NON_SENSITIVE;
  const tagRequest: QueryRequestItem = {
    request_id: TAG_REQUEST_ID,
    search_facet_tag_request_v2: {
      q: keyword,
      sensitive_filter: sensitiveFilter,
      limit: MAX_SUGGESTION_ITEMS,
    },
  };
  const officialSeriesRequest: QueryRequestItem = {
    request_id: SERIES_OFFICIAL_REQUEST_ID,
    search_series_by_title_request_v2: {
      q: keyword,
      sensitive_filter: sensitiveFilter,
      official_filter: query_search_v2.OfficialFilter.OFFICIAL_FILTER_OFFICIAL,
      require_series_page: {
        require_series_list: {
          require_id: true,
          require_title: true,
          require_is_official: true,
        },
        page: {
          page_number: 0,
          limit: MAX_SUGGESTION_ITEMS,
        },
      },
    },
  };
  const indiesSeriesRequest: QueryRequestItem = {
    request_id: SERIES_INDIES_REQUEST_ID,
    search_series_by_title_request_v2: {
      q: keyword,
      sensitive_filter: sensitiveFilter,
      official_filter:
        query_search_v2.OfficialFilter.OFFICIAL_FILTER_NOT_OFFICIAL,
      require_series_page: {
        require_series_list: {
          require_id: true,
          require_title: true,
          require_is_official: true,
        },
        page: {
          page_number: 0,
          limit: MAX_SUGGESTION_ITEMS,
        },
      },
    },
  };
  const userRequest: QueryRequestItem = {
    request_id: USER_REQUEST_ID,
    search_user_request_v2: {
      q: keyword,
      require_user_page: {
        require_user_list: {
          require_id: true,
          require_name: true,
          require_thumbnail: {
            require_serving_url: true,
          },
        },
        page: {
          page_number: 0,
          limit: MAX_SUGGESTION_ITEMS,
        },
      },
    },
  };

  return getQueriesForClient({
    request_list: [
      tagRequest,
      officialSeriesRequest,
      indiesSeriesRequest,
      userRequest,
    ],
  }).then((res): Suggestions => {
    const tags =
      res.response_list
        .find((res) => res.request_id === TAG_REQUEST_ID)
        ?.search_facet_tag_v2?.facet_tags?.map((tag): TagSuggestItem => {
          return {
            suggestType: 'tag',
            tagName: tag.tag ?? '',
            count: tag.count ?? 0,
          };
        }) ?? [];

    const officialSeries =
      res.response_list
        .find((res) => res.request_id === SERIES_OFFICIAL_REQUEST_ID)
        ?.search_series_v2?.series_page?.series_list?.map(
          (series): SeriesSuggestItem => {
            return {
              suggestType: 'series',
              title: series.title ?? '',
              seriesId: series.id ?? '',
              isOfficial: series.is_official ?? false,
            };
          }
        ) ?? [];

    const indiesSeries =
      res.response_list
        .find((res) => res.request_id === SERIES_INDIES_REQUEST_ID)
        ?.search_series_v2?.series_page?.series_list?.map(
          (series): SeriesSuggestItem => {
            return {
              suggestType: 'series',
              title: series.title ?? '',
              seriesId: series.id ?? '',
              isOfficial: series.is_official ?? false,
            };
          }
        ) ?? [];

    const users =
      res.response_list
        .find((res) => res.request_id === USER_REQUEST_ID)
        ?.search_user_v2?.user_page?.user_list?.map((user): UserSuggestItem => {
          return {
            suggestType: 'user',
            userId: user.id ?? '',
            name: user.name ?? '',
            thumbnail: user.thumbnail?.serving_url ?? '',
          };
        }) ?? [];

    return {
      tags,
      officialSeries,
      indiesSeries,
      users,
    };
  });
};

export const useSuggestion = (input: string | undefined) => {
  const { session } = useTellerSession();
  const { showSensitive } = useShowSensitive();
  const { isNgKeywords } = useNgKeywords();
  const debounceValue = useDebounce(input, 500);
  const isDebouncing = debounceValue !== input;

  const {
    data: suggestions,
    isLoading: _isLoading,
    error,
  } = useSWR(
    session?.uid && debounceValue && !isDebouncing
      ? ['/api/searchSuggestion/suggestions', debounceValue, !!showSensitive]
      : null,
    ([_, text, sensitive]) => fetchSuggestions(text, sensitive)
  );

  const isEmptyResult = useMemo(() => {
    if (!suggestions || (input && input.length === 0)) return false;

    return (
      suggestions.tags.length === 0 &&
      suggestions.officialSeries.length === 0 &&
      suggestions.indiesSeries.length === 0 &&
      suggestions.users.length === 0
    );
  }, [input, suggestions]);

  // NGワードは値を返さない
  if (isNgKeywords(input))
    return { suggestions: undefined, isLoading: false, error };

  const isLoading =
    _isLoading || (input && input.length > 0 && suggestions === undefined);

  return {
    suggestions,
    isEmptyResult,
    isLoading,
    error,
  };
};
