'use client';
import { ChangeEvent, InputHTMLAttributes, useCallback, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { Color } from '~/utils/styles/enums';
import { css } from '@styled-system/css';
import { HStack, Paragraph, styled } from '@styled-system/jsx';

type Config = Partial<{
  clearButton: boolean;
}>;

const DEFAULT_CONFIG: Config = {
  clearButton: true,
};

type Props = {
  config?: Config;
  onChangeText?: (text: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
} & InputHTMLAttributes<HTMLInputElement>;

export const SearchBar = ({
  config,
  onChangeText,
  inputRef,
  ...inputProps
}: Props) => {
  const enableClearButton = config?.clearButton ?? DEFAULT_CONFIG.clearButton;
  const [inputText, setInputText] = useState('');
  const empty = inputText.length === 0;
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputText(e.target.value);
      inputProps.onChange?.(e);
      onChangeText?.(e.target.value);
    },
    [inputProps, onChangeText]
  );

  const handleOnClickClear = useCallback(() => {
    setInputText('');
    if (inputRef?.current) {
      // @ts-ignore
      inputRef.current.value = '';
      onChangeText?.('');
    }
  }, [inputRef, onChangeText]);

  return (
    <>
      <HStack>
        <HStack
          bg="strong_bg"
          w={enableClearButton && !empty ? 'calc(100% - 70px)' : '100%'}
          h="40px"
          p="8px"
          borderRadius="8px"
          alignItems="center"
          transition="width 0.2s ease-out"
        >
          <FiSearch
            color={Color.ACCENT_1000}
            size={24}
            className={css({
              flexShrink: 0,
            })}
          />
          <styled.input
            ref={inputRef}
            type="text"
            placeholder="タイトル、作家名、タグで検索"
            _placeholder={{
              color: 'accent.500',
            }}
            border="none"
            outline="none"
            bg="transparent"
            color="accent.1000"
            fontSize="size_16"
            fontWeight="bold"
            width="100%"
            pt="2px"
            {...inputProps}
            onChange={handleOnChange}
          />
        </HStack>

        {enableClearButton && !empty && (
          <Paragraph
            minWidth="70px"
            color="content_secondary"
            onClick={handleOnClickClear}
            cursor="pointer"
            fontSize="size_14"
            animation="fade-in 0.2s ease-out"
          >
            キャンセル
          </Paragraph>
        )}
      </HStack>
    </>
  );
};
